import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { Role, User } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './create.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminUsersCreateComponent implements OnInit {
	adminPermission: boolean = false;
	entry: User;
	fieldgroups: any[];
	id: string;
	loading = 0;
	name: string = '';
	options: any = {};
	roles: Role[];
	url: string = '';

	constructor(
		private adminService: AdminService,
		private crudService: CRUDService,
		private breadcrumbService: BreadcrumbService,
		private jwtHelper: JwtHelperService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.USERS;

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.CREATE', routerLink: [this.url + '/' + PATH.CREATE + '/' + this.id] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.fieldgroups = [
			[
				{ type: 'text', key: 'Email', required: true, width: 400 },
				{ type: 'VehicleRolesList', key: 'VehicleRolesList', required: true, width: 400 },
				{ type: 'password', key: 'Password', required: true, width: 400 },
			]
		];

		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator' || 'CustomerAdministrator') {
			this.adminPermission = true;

			this.entry = {
				Email: null,
				Password: null,
				VehicleRolesList: null,
				UserConnectionSettings: null
			};
			this.getOptions();
		}
		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	createEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'list') {
						let listString = '';
						this.entry[field.key].forEach(element => {
							listString += element + ',';
						});
						this.entry[field.key] = listString.slice(0, -1);
					}
				});
			});
			this.adminService.createUser(this.entry).then(res => {
				//console.log('new created user:');
				//console.log(res);
				//console.log('(end of new created user)');

				//this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res.Id]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
				if(res && res.Id && res.Id.length > 0) {
					this.loading += 1;
					this.crudService.getOrCreateProgCfgBenutzerByVionUserEx(res.Id, res.Email, 0, 0, 0, 0).then(() => {
						//
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						});
					}).finally(() => {
						this.loading -= 1;
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + res.Id]);
					});
				}
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	// #endregion CRUD

	// #region Spezial

	getOptions(): void {
		this.loading += 1;
		this.adminService.getAllRoles().then(res => {
			const o = [];
			res.forEach(l => {
				if (!l.RoleName.toLowerCase().includes('visy')) {
					o.push(l);
				}
			});
			this.options.VehicleRolesList = o;
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	checkRequiredFields(): boolean {
		let valid = true;
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
					valid = false;
					this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('HEADERS.' + field.key) }), life: 3000 });
				}
			});
		});
		return valid;
	}

	// #endregion Spezial
}
