import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module, Operation } from '@app/models/permission';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { User } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
	templateUrl: './detail.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService, ConfirmationService]
})
export class AdminUsersDetailComponent implements OnInit {
	adminPermission: boolean = false;
	visyAdminPermission: boolean = false;
	apiUrl: string = '';
	entry: User;
	entryProgCfgBenutzer: any = null;
	fieldgroups: any[] = [];
	fieldgroupsProgCfgBenutzer: any[] = [];
	id: string;
	loading: number = 0;
	name: string = '';
	permissions: any[];
	permissionsProgCfgBenutzer: any[];
	state: any = {};
	stateName: string = '';
	url: string = '';
	url_edit: string = PATH.EDIT;
	url_detail: string = PATH.DETAIL;

	constructor(
		private adminService: AdminService,
		private crudService: CRUDService,
		private breadcrumbService: BreadcrumbService,
		private confirmationService: ConfirmationService,
		private jwtHelper: JwtHelperService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = href[href.length - 1];

		this.apiUrl = 'Users';
		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.USERS;
		this.stateName = 'state' + this.apiUrl + 'Detail';

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.DETAIL', routerLink: [this.url + '/' + PATH.DETAIL + '/' + this.id] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.permissions = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.permissionsProgCfgBenutzer = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.fieldgroups = [
			[
				{ type: 'text', key: 'Email', required: true, width: 400 },
				{ type: 'text', key: 'RoleName', required: true, width: 400 },
			]
		];

		this.fieldgroupsProgCfgBenutzer = [
			[
				{ type: 'text', sub: "ADMIN", key: 'name', required: true, width: 400 }
			]
		];

		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator' || 'CustomerAdministrator') {
			this.adminPermission = true;
			if (role === 'ViSyAdministrator') {
				this.visyAdminPermission = true;
			}
			this.getEntry();
		}

		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	createCorrespondingProgCfgBenutzer() {
		this.entryProgCfgBenutzer = null;
		if(this.adminPermission === true) {
			if( this.entry.Id && this.entry.Id.length > 1 ) {
				this.loading += 1;
				this.crudService.getOrCreateProgCfgBenutzerByVionUserEx(
					this.entry.Id,
					this.entry.Email,
					this.entry.ReadPermissions ? this.entry.ReadPermissions : 0,
					this.entry.CreatePermissions ? this.entry.CreatePermissions : 0,
					this.entry.UpdatePermissions ? this.entry.UpdatePermissions : 0,
					this.entry.DeletePermissions ? this.entry.DeletePermissions : 0
				).then(resProgCfg => {
					if(resProgCfg != null && resProgCfg != undefined && resProgCfg.ds_this_id != null && resProgCfg.ds_this_id != undefined && resProgCfg.ds_this_id > 0) {
						this.entryProgCfgBenutzer = resProgCfg;
						this.fieldgroupsProgCfgBenutzer.forEach(fg => {
							fg.forEach(field => {
								if (field.type == 'date') {
									if (this.entry[field.key] != null) {
										this.entry[field.key] = new Date(this.entry[field.key]);
									}
								}
							});
						});
					}
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		}
	}

	deleteEntry(): void {
		this.confirmationService.confirm({
			message: this.translate.instant('CONFIRMATION.DELETE_QUESTION'),
			header: this.translate.instant('CONFIRMATION.CONFIRM'),
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: this.translate.instant('CONFIRMATION.YES'),
			rejectLabel: this.translate.instant('CONFIRMATION.NO'),
			accept: () => {
				this.loading += 1;
				this.adminService.deleteUser(this.entry.Id).then(res => {
					this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.DELETED'), life: 3000 });
					this.router.navigate([this.url + '/list/']);
				}).catch(err => {
					err.error.forEach(e => {
						this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		});
	}

	getEntry(): void {
		this.loading += 1;
		this.adminService.getUser(this.id).then(res => {
			this.entry = res;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
				});
			});
			const bookedModules = [];
			this.permissions.forEach(p => {
				if (this.isBookedModule(p.key)) {
					bookedModules.push(p);
				}
			});
			this.permissions = bookedModules;

			if(this.adminPermission === true) {
				this.loading += 1;
				this.crudService.getProgCfgBenutzerByVionUserId(this.entry.Id).then(resProgCfg => {
					if(resProgCfg != null && resProgCfg != undefined && resProgCfg.ds_this_id != null && resProgCfg.ds_this_id != undefined && resProgCfg.ds_this_id > 0) {
						this.entryProgCfgBenutzer = resProgCfg;
						this.fieldgroupsProgCfgBenutzer.forEach(fg => {
							fg.forEach(field => {
								if (field.type == 'date') {
									if (this.entry[field.key] != null) {
										this.entry[field.key] = new Date(this.entry[field.key]);
									}
								}
							});
						});
					}
				}).catch(err => {
					err.error.forEach(e => {
						if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
						} else {
							this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
						}
					})
				}).finally(() => {
					this.loading -= 1;
				});
			}
		}).catch(err => {
			err.error.forEach(e => {
				if (this.translate.instant('ERRORCODE.' + e.Code) === 'ERRORCODE.' + e.Code) {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.UNKNOWN', { code: e.Code }), detail: e.Code + ": " + e.Description, life: 30000 });
				} else {
					this.messageService.add({ severity: 'error', summary: this.translate.instant('ERRORCODE.' + e.Code), detail: this.translate.instant('ERRORMSG.' + e.Code), life: 30000 });
				}
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Spezial

	isBookedModule(module: Module): boolean {
		return (this.entry.UserConnectionSettings.BookedModules & Math.pow(2, module)) == Math.pow(2, module);
	}

	isPermitted(module: Module, operation: Operation): boolean {
		let permission;
		if (operation == 0) {
			permission = (this.entry.CreatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 1) {
			permission = (this.entry.ReadPermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 2) {
			permission = (this.entry.UpdatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 3) {
			permission = (this.entry.DeletePermissions & Math.pow(2, module)) == Math.pow(2, module);
		}
		return permission;
	}

	isPermittedProgCfgBenutzer(module: Module, operation: Operation): boolean {
		let permission;
		if (operation == 0) {
			permission = (this.entryProgCfgBenutzer["create_permissions"] & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 1) {
			permission = (this.entryProgCfgBenutzer["read_permissions"] & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 2) {
			permission = (this.entryProgCfgBenutzer["update_permissions"] & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 3) {
			permission = (this.entryProgCfgBenutzer["delete_permissions"] & Math.pow(2, module)) == Math.pow(2, module);
		}
		return permission;
	}

	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	// #endregion Spezial
}
