import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Module, Operation } from '@app/models/permission';
import { CRUDService } from '@app/services/crud.service';
import PATH from '@assets/routes/routes.json';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BreadcrumbService } from '@components/breadcrumb.service';
import { User } from '@models/admin';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from '@services/admin.service';
import { MessageService } from 'primeng/api';

@Component({
	templateUrl: './edit.component.html',
	styleUrls: ['../style.scss'],
	providers: [MessageService]
})
export class AdminUsersEditComponent implements OnInit {
	adminPermission: boolean = false;
	apiUrl: string = '';
	displayDialog: boolean = false;
	entry: User;
	fieldgroups: any[];
	id: string;
	loading = 0;
	name: string = '';
	newPassword: string;
	options: any = {};
	permissions: any[];
	state: any = {};
	stateName: string = '';
	url: string = '';

	constructor(
		private adminService: AdminService,
		private crudService: CRUDService,
		private breadcrumbService: BreadcrumbService,
		private jwtHelper: JwtHelperService,
		private messageService: MessageService,
		private router: Router,
		public translate: TranslateService,
	) {
		const href = this.router.url.split('/');
		this.id = href[href.length - 1];

		this.apiUrl = 'Users';
		this.name = 'MENU.NUTZER';
		this.url = '/' + PATH.USERS;
		this.stateName = 'state' + this.apiUrl + 'Edit';

		this.breadcrumbService.setItems([
			{ label: 'MENU.ADMIN' },
			{ label: this.name, routerLink: [this.url] },
			{ label: 'BREADCRUMBS.EDIT', routerLink: [this.url + '/' + PATH.EDIT + '/' + this.id] }
		]);
	}

	// #region Angular

	ngOnInit(): void {
		this.loading += 1;

		this.permissions = [
			{ key: Module.OnlineCollections, label: 'Online Leerungen' },
			{ key: Module.OnlineVehicles, label: 'Online Fahrzeuge' },
			{ key: Module.BHV, label: 'BHV' },
			{ key: Module.ReversingCadastral, label: 'Rückfahrkataster' },
			{ key: Module.Masterdata, label: 'Stammdaten' },
		];

		this.fieldgroups = [
			[
				{ type: 'text', key: 'Email', required: true, width: 400 },
				{ type: 'VehicleRolesList', key: 'VehicleRolesList', required: true, width: 400 },
			]
		];

		this.state = JSON.parse(localStorage.getItem(this.stateName));
		this.setHorizontalGroups(((this.state && this.state.horizontalGroups !== null) ? this.state.horizontalGroups : true));

		const role = this.jwtHelper.decodeToken(localStorage.getItem('jwt'))['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
		if (role === 'ViSyAdministrator' || 'CustomerAdministrator') {
			this.adminPermission = true;

			this.getEntry();
			this.getOptions();
		}
		this.loading -= 1;
	}

	// #endregion Angular

	// #region CRUD

	editEntry(): void {
		if (this.checkRequiredFields()) {
			this.loading += 1;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'list') {
						let listString = '';
						this.entry[field.key].forEach(element => {
							listString += element + ',';
						});
						this.entry[field.key] = listString.slice(0, -1);
					}
				});
			});
			this.adminService.editUser(this.id, this.entry).then(res => {
				//this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.entry.Id]);
				this.messageService.add({ severity: 'success', summary: this.translate.instant('MESSAGES.SUCCESSFUL'), detail: this.translate.instant('MESSAGES.SAVED'), life: 3000 });
				if( this.entry && this.entry.Id && this.entry.Id.length > 0 ) {
					this.loading += 1;
					this.crudService.updateProgCfgBenutzerByVionUserEx(
						this.entry.Id,
						this.entry.Email,
						this.entry.ReadPermissions,
						this.entry.CreatePermissions,
						this.entry.UpdatePermissions,
						this.entry.DeletePermissions
					).then(() => {
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.entry.Id]);
					}).catch(err => {
						err.error.forEach(e => {
							this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
						});
						this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.entry.Id]);
					}).finally(() => {
						this.loading -= 1;
					});
				} else {
					this.router.navigate([this.url + '/' + PATH.DETAIL + '/' + this.entry.Id]);
				}
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		}
	}

	getEntry(): void {
		this.loading += 1;
		this.adminService.getUser(this.id).then(res => {
			this.entry = res;
			this.fieldgroups.forEach(fg => {
				fg.forEach(field => {
					if (field.type == 'date') {
						if (this.entry[field.key] != null) {
							this.entry[field.key] = new Date(this.entry[field.key]);
						}
					}
					else if (field.type == 'list') {
						this.entry[field.key] = this.entry[field.key].split(',').map(Number);
					}
				});
			});
			const bookedModules = [];
			this.permissions.forEach(p => {
				if (this.isBookedModule(p.key)) {
					bookedModules.push(p);
				}
			});
			this.permissions = bookedModules;
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	// #endregion CRUD

	// #region Spezial

	getOptions(): void {
		this.loading += 1;
		this.adminService.getAllRoles().then(res => {
			const o = [];
			res.forEach(l => {
				if (!l.RoleName.toLowerCase().includes('visy')) {
					o.push(l);
				}
			});
			this.options.VehicleRolesList = o;
		}).catch(err => {
			err.error.forEach(e => {
				this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
			})
		}).finally(() => {
			this.loading -= 1;
		});
	}

	stringIsNullOrWhitespace(value): boolean {
		if (value !== null && value !== undefined) {
			let val = value + '';
			if (val.trim().length > 0) {
				return false;
			}
		}
		return true;
	}

	checkRequiredFields(): boolean {
		let valid = true;
		this.fieldgroups.forEach(fg => {
			fg.forEach(field => {
				if (field.required && this.stringIsNullOrWhitespace(this.entry[field.key])) {
					valid = false;
					this.messageService.add({ severity: 'error', summary: this.translate.instant('MESSAGES.ERROR'), detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('HEADERS.' + field.key) }), life: 3000 });
				}
			});
		});
		return valid;
	}

	isBookedModule(module: Module): boolean {
		return (this.entry.UserConnectionSettings.BookedModules & Math.pow(2, module)) == Math.pow(2, module);
	}

	isPermitted(module: Module, operation: Operation): boolean {
		let permission = true;
		if (operation == 0) {
			permission = (this.entry.CreatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 1) {
			permission = (this.entry.ReadPermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 2) {
			permission = (this.entry.UpdatePermissions & Math.pow(2, module)) == Math.pow(2, module);
		} else if (operation == 3) {
			permission = (this.entry.DeletePermissions & Math.pow(2, module)) == Math.pow(2, module);
		}
		return permission;
	}

	setPassword(): void {
		if (this.newPassword) {
			this.loading += 1;
			this.adminService.setPassword(this.entry.Id, this.newPassword).then(res => {
				this.displayDialog = false;
				this.messageService.add({
					severity: 'success',
					summary: this.translate.instant('MESSAGES.SUCCESSFUL'),
					detail: this.translate.instant('MESSAGES.SAVED'),
					life: 3000
				});
			}).catch(err => {
				err.error.forEach(e => {
					this.messageService.add({ severity: 'error', summary: 'Error ' + e.Code, detail: e.Description, life: 30000 });
				})
			}).finally(() => {
				this.loading -= 1;
			});
		} else {
			this.messageService.add({
				severity: 'error',
				summary: this.translate.instant('MESSAGES.ERROR'),
				detail: this.translate.instant('MESSAGES.REQUIRED_FIELD', { field: this.translate.instant('SETTINGS.CHANGE_PASSWORD.NEW_PASSWORD') }),
				life: 3000
			});
		}
	}

	setPermission(module: Module, operation: Operation, value: boolean): void {
		if (operation == 0) {
			if (value) {
				this.entry.CreatePermissions = this.entry.CreatePermissions | Math.pow(2, module);
			} else {
				this.entry.CreatePermissions = this.entry.CreatePermissions - Math.pow(2, module);
			}
		} else if (operation == 1) {
			if (value) {
				this.entry.ReadPermissions = this.entry.ReadPermissions | Math.pow(2, module);
			} else {
				this.entry.ReadPermissions = this.entry.ReadPermissions - Math.pow(2, module);
			}
		} else if (operation == 2) {
			if (value) {
				this.entry.UpdatePermissions = this.entry.UpdatePermissions | Math.pow(2, module);
			} else {
				this.entry.UpdatePermissions = this.entry.UpdatePermissions - Math.pow(2, module);
			}
		} else if (operation == 3) {
			if (value) {
				this.entry.DeletePermissions = this.entry.DeletePermissions | Math.pow(2, module);
			} else {
				this.entry.DeletePermissions = this.entry.DeletePermissions - Math.pow(2, module);
			}
		}
	}

	setHorizontalGroups(value: boolean): void {
		this.state = JSON.parse(localStorage.getItem(this.stateName));
		if (!this.state) {
			this.state = {};
		}
		this.state.horizontalGroups = value;
		localStorage.setItem(this.stateName, JSON.stringify(this.state));
	}

	// #endregion Spezial
}
